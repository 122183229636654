<template>
<Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
  <div class="height-div">
      <transition name="slide-fade">
        <div id="emisiones"  class="container-small flex-colum-center a  pt-16 pb-16" v-if="animation1" >
            <p class="title-2 text-center"> Yipi mueve México,</p>
            <p class="title-2  text-center" style="color: #3A3838;line-height:.8;">sin emisiones</p>
          <video class="video-emisiones" loop autoplay muted >
              <source src="../assets/animacion/17/Yipi_MueveMexico.webm" type="video/webm">
          <source src="../assets/animacion/17/Yipi_MueveMexico.mp4" type="video/mp4">
      
          </video>
      </div>
    </transition>
  </div>
</Intersect>
</template>
<script>
  import Intersect from 'vue-intersect'
export default {
  components:{
    Intersect
  },
  data:()=>({
   animation1:false 
  })
}
</script>
<style scoped>
.video-emisiones{
  width: 1200px;
  height: 500px;
  object-fit: contain;
}


.flex-colum-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-small-1{
    text-align: center;
    line-height: 1;
    font-size: 1rem;
    color: #686868;
    font-family: Avenir;
    margin-top: 7px;
    padding-bottom: 5px;
}

.space-25 {
  padding-left: 20px;

}
.space-mr-5{
margin-right: 6px;
}
.center-width {
  width: 100%;
  text-align: center;
}
.line{
  display: flex;
  align-items: flex-end;

  padding-bottom: 15px;
    border-bottom: 5px solid #00E882;

}
.display-flex {
  display: flex;
}
.text-medium-2 {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 4rem;
  font-family: BebasNeue;
  color: black;
  height: 70px; 

}

.text-large-5 {
  font-size: 5.5rem;
  font-family: BebasNeue;
  color:  #00E882;
  height: 95px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.container-text {
  width: 250px;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: baseline;
}

.list-icons {
  display: flex;
  width: 100%;
  justify-content: center;
}
.icon-big {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  width: auto;
  height: 250px;
  object-fit:contain;

}

@media (max-width:1050px) {
    .icon-big{
        width: 150px;
        height: 150px;
        margin-left: 10px;
        margin-right: 10px;

    }
   
    .text-small-1{
        text-align: center;
    line-height: 1;
    font-size: .85rem;
    color: #686868;
    font-family: Avenir;
  
    text-align: center;
  
    }
    .text-medium-2{
            padding-left: 30px;
  padding-right: 30px;
  font-size: 2.1rem;
  font-family: BebasNeue;
  color: black;
  height: 35px; 
    }
    
.text-large-5 {
  font-size: 3rem;
  font-family: BebasNeue;
  color: var(--green-degradado-01);
  height:50px;
}

}
@media (max-width:600px) {
    .icon-big{
        width:105px;
        height: 90px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .text-small-1{
    line-height: 1;
    font-size: .6rem;
    color: #686868;
    font-family: Avenir;
  
    text-align: center;
  
    }
    .text-medium-2{
            padding-left: 0px;
  padding-right: 0px;
  font-size: 1rem;
  font-family: BebasNeue;
  color: black;
  height: 18px; 
  margin-left: 15px;
  margin-right: 15px;
   
    }
    
.text-large-5 {
  font-size: 1.35rem;
  font-family: BebasNeue;
  color: var(--green-degradado-01);
  height:24px;
}
.video-emisiones{
  width: 100%;
  height: auto;
  object-fit: contain;
}

.space-25 {
  padding-left: 12px;
}
}
</style>